import React, {createContext, useEffect, useState} from "react";

import api from "../utils/api";

import {useCookies} from "react-cookie";

export const authContext = createContext({
	isLoggedIn: false,
});

export function ProvideAuth({children}) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useProvideAuth() {
	const [cookies, setCookie, removeCookie] = useCookies(["token", "id"]);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	useEffect(() => {
		setIsLoading(true);
		if (token) {
			api
				.getUserGuides(token, {id: idUser})
				.then((res) => {
					setIsLoggedIn(true);
					setIsLoading(false);
				})
				.catch((err) => {
					removeCookie("token");
					removeCookie("id");

					setIsLoggedIn(false);
					setIsLoading(false);
				});
			return;
		}
		setIsLoading(false);
	}, [idUser, token, removeCookie]);

	const signIn = (email, password) => {
		return api.login({email: email, password: password}).then((res) => {
			if (res.errors) {
				return res.errors;
			} else {
				setIsLoggedIn(true);

				if (rememberMe) {
					const yearInSeconds = 31556952;
					setCookie("token", res.token, {path: "/", maxAge: yearInSeconds});
					setCookie("id", res.id, {path: "/", maxAge: yearInSeconds});
				} else {
					setCookie("token", res.token, {path: "/"});
					setCookie("id", res.id, {path: "/"});
				}

				setIsLoading(false);

				return res.token;
			}
		});
	};

	const logout = (callback) => {
		setIsLoggedIn(false);
		removeCookie("token");
		removeCookie("id");
		callback();
	};

	return {
		signIn,
		logout,
		token,
		idUser,
		isLoggedIn,
		setRememberMe,
		isLoading,
		setIsLoading,
	};
}
